import { Terminal } from "xterm";
import { FitAddon } from 'xterm-addon-fit';

console.log('Damn, those alien bastards are gonna pay for shooting up my ride.')

export const myTerminal = () => {
    const theme = {
        foreground: '#84c138',
        background: '#100b05',
        cursor: '#23ff18',
        black: '#000000',
        brightBlack: '#666666',
        red: '#b6214a',
        brightRed: '#e50000',
        green: '#00a600',
        brightGreen: '#86a93e',
        yellow: '#bfbf00',
        brightYellow: '#e5e500',
        blue: '#246eb2',
        brightBlue: '#0000ff',
        magenta: '#b200b2',
        brightMagenta: '#e500e5',
        cyan: '#00a6b2',
        brightCyan: '#00e5e5',
        white: '#bfbfbf',
        brightWhite: '#e5e5e5'
    };

    let command = '';
    let terminal = new Terminal({
        cursorBlink: true,
        rows: 20,
        theme,
        rightClickSelectsWord: true,
        cursorStyle: 'underline',
        altClickMovesCursor: true,
        windowsMode: false,
        allowTransparency: true,
        convertEol: true,
        });

    const enter = '\r';
    const backSpace = '\u007F';
    const tab = '\u0009';
    const user = '$';

    const background = document.getElementsByClassName('background-div')[0];

    const fitAddon = new FitAddon();
    terminal.loadAddon(fitAddon);
    terminal.open(document.getElementById('terminal'));
    fitAddon.fit();
    init();

    prompt(terminal);
    terminal.focus();

    function prompt(term) {
        command = '';
        term.write(`\r\n${user} `);
    }

    terminal.onData((e) => {
        switch (e) {
            case tab:
                autoComplete();
                break;

            case enter:
                runCommand(terminal, command);
                command = '';
                break;

            case backSpace:
                if (terminal._core.buffer.x > 2) {
                    terminal.write('\b \b');
                    if (command.length > 0) {
                        command = command.substr(0, command.length - 1);
                    }
                }
                break;

            default:
                if ((e >= String.fromCharCode(0x20) && e <= String.fromCharCode(0x7B)) || e >= '\u00a0') {
                    command += e;
                    terminal.write(e);
                }
        }
    });

    function init() {
        terminal.reset();
        terminal.write('$ Welcome to Logic Injection. Type -help to show commands.');
    }

    let commands = {
        clear: {
            run: () => {
                background.classList.add('blurry');
                init();
            },
        },
        ls: {
             run: () => { commands['-help'].run() }
        },
        '-help': {
            run: () => {
                terminal.writeln([
                    '',
                    ...Object.keys(commands)
                        .filter(command => commands[command].description)
                        .map(e => {
                            return `  ${e.padEnd(10)} ${commands[e].description}`;
                        })
                ].join('\n\r'));
            },
            description: 'Prints available commands',
        },
        '-about': {
            run: () => {
                terminal.writeln([
                    'Logic Injection IT AB is an awesome IT consultant company founded in 2019!',
                    '',
                    '  NAME  |       TITLE        |      DESCRIPTION     | XP (years)',
                    '-------------------------------------------------------------------',
                    `Erik    | Software Developer | Java expert          | ${calculateExperience(2007)} years`,
                    `Fredrik | Software Developer | Señor Developer      | ${calculateExperience(2009)} years`,
                    `Johan   | Software Developer | Kodapa               | ${calculateExperience(1997)} years`,
                    `Jonatan | Business Architect | Meeting professional | ${calculateExperience(2011)} years`,
                ].join('\n\r'))
            },
            description: 'Who are we?'
        },
        '-contact': {
            run: () => {
                terminal.writeln([
                    'Logic Injection IT AB',
                    'c/o BYN',
                    'Storgatan 82 A',
                    '352 46 Vaxjo',
                    '',
                    'Email: info@logicinjection.se'
                ].join('\n\r'));
            },
            description: 'Need to get hold of us?',
        },
        '-job': {
            run: () => {
                terminal.writeln(['Looking for a new gig eh?',
                    '',
                    'Send us an email to info@logicinjection.se and tell us all about you and your favourite cold beverage.',
                    'If you are a developer, a link to your GitHub awesomeness is appreciated!'
                ].join('\n\r'))
            },
            description: 'Are you looking for a job? Give us a holler!'
        },
        LIIT: {
            run: () => {
                background.classList.remove('blurry');

                terminal.writeln([
                    'Curious lil\' bugger are you?',
                    'Here, have a drink on us!',
                    '',
                    '        .',
                    '       .',
                    '      . .',
                    '       ...',
                    '     \\~~~~~/',
                    '      \\   /',
                    '       \\ /',
                    '        V',
                    '        |',
                    '        |',
                    '       ---',
                    'LONG ISLAND ICED TEA'
                ].join('\n\r'))
            }
        },
        exit: {
            run: () => {
                terminal.dispose();
            }
        },
        close: {
            run: () => {
                terminal.dispose();
            }
        },
        iddqd: {
            run: () => {
                terminal.writeln([
                    '+-----------------------------------------------------------------------------+',
                    '| |       |\\                                           -~ /     \\  /          |',
                    '|~~__     | \\                                         | \\/       /\\          /|',
                    '|    --   |  \\                                        | / \\    /    \\     /   |',
                    '|      |~_|   \\                                   \\___|/    \\/         /      |',
                    '|--__  |   -- |\\________________________________/~~\\~~|    /  \\     /     \\   |',
                    '|   |~~--__  |~_|____|____|____|____|____|____|/ /  \\/|\\ /      \\/          \\/|',
                    '|   |      |~--_|__|____|____|____|____|____|_/ /|    |/ \\    /   \\       /   |',
                    '|___|______|__|_||____|____|____|____|____|__[]/_|----|    \\/       \\  /      |',
                    '|  \\mmmm :   | _|___|____|____|____|____|____|___|  /\\|   /  \\      /  \\      |',
                    '|      B :_--~~ |_|____|____|____|____|____|____|  |  |\\/      \\ /        \\   |',
                    '|  __--P :  |  /                                /  /  | \\     /  \\          /\\|',
                    '|~~  |   :  | /                                 ~~~   |  \\  /      \\      /   |',
                    '|    |      |/                        .-.             |  /\\          \\  /     |',
                    '|    |      /                        |   |            |/   \\          /\\      |',
                    '|    |     /                        |     |            -_   \\       /    \\    |',
                    '+-----------------------------------------------------------------------------+',
                    '|          |  /|  |   |  2  3  4  | /~~~~~\\ |       /|    |_| ....  ......... |',
                    '|          |  ~|~ | % |           | | ~J~ | |       ~|~ % |_| ....  ......... |',
                    '|   AMMO   |  HEALTH  |  5  6  7  |  \\===/  |    ARMOR    |#| ....  ......... |',
                    '+-----------------------------------------------------------------------------+',
                ].join('\n\r'))
            }
        },
        dncornholio: {
            run: () => {
                terminal.writeln('YOU\'RE TOO GOOD TO BE CHEATING!')
            }
        },
        warneverchanges: {
            run: () => {
                terminal.writeln('Hey, chin up. I know the night just got darker, but it wont last forever.')
            }
        }
    };

    function runCommand(terminal, text) {
        if (command === '') {
            return;
        }

        const sanitizedCommand = text.trim().split(' ')[0];
        if (sanitizedCommand.length > 0) {
            if (sanitizedCommand !== 'clear') {
                terminal.writeln('');
            }

            if (sanitizedCommand in commands) {
                commands[sanitizedCommand].run();
                prompt(terminal);
                return;
            }

            terminal.writeln(`${sanitizedCommand}: command not found`);
        }

        prompt(terminal);
    }

    function autoComplete() {
        for (const key of Object.keys(commands).filter((command) => commands[command].description )) {
            if (key.startsWith(command)) {
                const autoComplete = key.substring(command.length);
                terminal.write(autoComplete);
                command += autoComplete;
                return;
            }
          }
    }

    function calculateExperience(startYear) {
        return new Date().getFullYear() - startYear;
    }

    document.getElementsByClassName('xterm-selection-layer')[0].focus();
}