import './css/main.css';
import { myTerminal } from './js/terminal';

import React, { useEffect } from 'react';
import logo from './img/liit_logo.png';


const App = () => {
  useEffect(() => {
    myTerminal();
  }, []);

  return (
    <div className="App">
      <div className={'main-grid'}>
        <div className={'content-div'}>
          <img className={'img-logo'} alt={'LIIT'} src={logo} />
            <div id={'terminal'} className={'terminal-div'} />
        </div>
        <div className={'background-div blurry'} />
      </div>
    </div>
  );
}

export default App;
